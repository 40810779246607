<template>
	<div class="container">
		<div class="flex-row align-center justify-center nav">
			<div class="nav-title-bg"></div>
			<div class="nav-title">历史订单</div>
		</div>
		<div class="empty-text" v-if="isRequest&& list.length == 0">没有订单</div>
		<van-list v-model="loading" @load="getBTCOrderList" :finished="finished" finished-text="没有更多了"
			:error.sync="error" error-text="请求失败，点击重新加载">
			<div class="item flex-row align-center space-between" v-for="(item,index) in list" :key="index">
				<div class="flex-column">
					<div class="item-name">{{item.productName==''?'盲盒':item.productName}}</div>
					<div class="item-no">订单号:{{item.number}}</div>
				</div>
				<div class="item-price">￥{{item.payAmount}}</div>
			</div>
		</van-list>

	</div>
</template>

<script>
	import {
		Toast,
		Dialog
	} from 'vant';
	import 'vant/lib/toast/index.css';
	import 'vant/lib/dialog/index.css';
	import config from '../config/config.js';
	import util from '../config/util.js';
	export default {
		data() {
			return {
				list: [],
				more: true,
				pageNum: 1,
				pageSize: 10,
				loading: false,
				finished: false,
				error: false,
				isRequest: 0
			}
		},
		methods: {
			getBTCOrderList() {
				console.log('qwer')
				const that = this;
				let params = new FormData()
				params.append('pageNum', that.pageNum)
				params.append('pageSize', that.pageSize)
				that.axios.post(config.requestUrl + '/front/order/getBTCOrderList', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						let list = that.list;
						let newList = response.data.obj.list;
						let finished = false;
						if (response.data.obj.isLastPage || !response.data.obj.hasNextPage) finished =
							true;
						list = list.concat(newList);
						that.list = list;

						that.finished = finished;
						that.error = false;
						that.pageNum = that.pageNum + 1
						this.isRequest = 1;
						that.loading = false;
					} else {
						that.loading = false;
						that.error = true
						Toast(response.data.msg);
					}
				}, response => {
					that.loading = false;
					that.error = true
					Toast('获取失败，请稍后重试');
				})
			}
		},
		mounted() {
			util.wxConfig();
		},
	}
</script>


<style scoped="scoped">
	.container {
		width: 100%;
		background: #F4F4F4;
		min-height: 100%;
	}

	.item {
		width: 710px;
		height: 150px;
		margin: 20px auto 0;
		padding: 40px 30px;
		box-sizing: border-box;
		background: #ffffff;
		border-radius: 10px;

	}

	.item-name {
		font-size: 30px;
		font-weight: 600;
		color: #000000;
	}

	.item-no {
		margin-top: 20px;
		font-size: 26px;
		font-weight: 400;
		color: #6D7278;
	}

	.item-price {
		font-size: 30px;
		font-weight: 600;
		color: #000000;
	}
</style>
